import React from "react";
import {List, Datagrid, TextField, ReferenceField, Filter, FunctionField} from "react-admin";
import filterCreators from "../../utils/filterCreators";
import { TablePagination } from "../TablePagination/TablePagination";
import TranslatedField from "../fields/TranslatedReferenceField/TranslatedField";
import {useResourcePermissions} from '../../utils/useResourcePermissions'

const Filters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
  </Filter>
);

const ProductsList = (props) => {
  const {loading, actions} = useResourcePermissions(props.resource)

  if (loading) {
    return null
  }

  const newProps = {...props}
  // newProps.hasCreate = actions.includes('create')

  return (
    <List
      {...newProps}
      empty={false}
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<Filters variant="outlined" />}
      pagination={<TablePagination />}
      perPage={TablePagination.defaultPerPage}
    >
      <Datagrid rowClick="edit">
        {/*<TextField source="id" />*/}
        <TextField source="name" sortable={false} />
        <ReferenceField source="status" reference="fbp_pricing/productstatus" link={false} sortable={false}>
           <TranslatedField
               translationKey="resources.fbp_pricing/product.status"
               field="iname"
           />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default ProductsList;
